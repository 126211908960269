import * as React from "react"
// import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import { Link } from "gatsby"

const IndexPage = () => (
  <Layout title="Home">
    <h1>Home</h1>
    <p>
      <Link to="/discography">My discography!</Link>
    </p>
    <p>
      <Link to="/tracks">
        Full chronological list of my tracks individually.
      </Link>
    </p>
    <p>
      News/Blog
    </p>
    <p>
      Bio/About
    </p>
    <p>
      Contact
    </p>
  </Layout>
)

export default IndexPage
